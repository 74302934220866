import { EnvironmentData } from './get-environment.models';

export const oneDayInSeconds = 86_400;

export const defaultEnvironmentState: EnvironmentData = {
  environment: 'production',
  appName: '',
  appVersion: '',
  homePageUrl: 'https://pracuj.pl',
  accountPageUrl: 'https://pracuj.pl/konto',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  changePasswordRedirectUrl: '/',
  ssoLogoutUrl: '',
  apiGatewayAddress: '',
  apiConnectionTimeout: '',
  csrfCookieName: '',
  csrfHeaderName: '',
  cookieDomain: '.pracuj.pl',
  gtmContainerId: '',
  alabamaGatewayAddress: '',
  trackerCollectorEndpoint: '',
  trackerCoreUrl: '',
  trackerEnabled: '',
  accessTokenKey: '_gpauth',
  refreshTokenKey: '_gpauthrt',
  onboardingProcessPageUrl: 'https://onboarding.pracuj.pl',
  onboardingProcessCookieName: '',
};
